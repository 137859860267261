import { dateFormatter } from "../../components/DateFormatter";
import { getUserProfileData } from "../../store/searchUser";
import { useAppSelector } from "../../hooks/reduxTypedHooks";
import { useHistory } from "react-router";

export default function TeamData() {
  const userProfileData = useAppSelector(getUserProfileData);
  const history = useHistory();
  function teamClick(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.preventDefault();

    if (e.type === "auxclick") {
      window.open(
        `/team-account?teamId=${id}`,
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      return history.push({
        pathname: "/team-account",
        search: `?teamId=${id}`,
      });
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-5">
          <legend>Team Data</legend>
        </div>
      </div>

      <dl className="row">
        {/* Team Name */}
        <dt className="col-sm-5">Team</dt>
        <dd className="col-sm-7">
          {userProfileData.teamName ? (
            <button
              className="ms-n2 btn btn-link text-decoration-none fw-bold btn-sm theme-btn-hover py-0"
              onClick={(e) => teamClick(e, userProfileData.teamId)}
              onAuxClick={(e) => teamClick(e, userProfileData.teamId)}
            >
              {userProfileData.teamName}
            </button>
          ) : (
            "--"
          )}
        </dd>

        {/* Joined Team Date */}
        <dt className="col-sm-5">Joined Team</dt>
        <dd className="col-sm-7">
          {userProfileData.joinedTeamDate
            ? dateFormatter(userProfileData.joinedTeamDate)
            : "--"}
        </dd>
         {/* Part count */}
         <dt className="col-sm-5">Created Parts</dt>
        <dd className="col-sm-7">
          {userProfileData.partCount}
        </dd>

           {/* Team Part count */}
        <dt className="col-sm-5">Total Parts</dt>
        <dd className="col-sm-7">
          {userProfileData.totalPartCount}
        </dd>

        {/* Team Part count */}
        <dt className="col-sm-5">Recent import sessions</dt>
          <dd className="col-sm-7">
            {userProfileData.importSessionCount}
        </dd>
      </dl>
    </>
  );
}
